<template>
  <div>
    <div class="webHeader" :class="{'bgwhite':!isHome}">
      <div class="flex_row_between centeerpart">
        <div class="logopart flex_row_left" @click="toHome()">
          <img class="logo" src="@/assets/logo.png" alt="" />
          <div class="navtitle" :class="{'bluenavtitle':!isHome}">脑健康训练</div>
        </div>
        <div class="flex_row_center opratepart">
          <div v-if="!isLogin" class="btn" :class="{'bluebtn':!isHome}" @click="showLogin()">登录</div>
          <div v-if="!isLogin" class="btn register" :class="{'whiteregister':!isHome}" @click="showRegister()">
            注册
          </div>
          <el-dropdown v-if="isLogin">
            <div class="flex_row_center el-dropdown-link">
              <div class="username" :class="{'blackusername':!isHome}">{{nickname?nickname:''}}</div>
              <el-badge is-dot class="badge" v-if="isRead > 0">
                <el-avatar :size="40" :src="avatar ? avatar : logoimg"></el-avatar>
              </el-badge>
              <el-avatar v-else :size="40" :src="avatar ? avatar : logoimg"></el-avatar>
              <img class="downicon" src="@/static/common/downicon.png" alt="" />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div class="flex_row_left" @click="toMy()">
                  <img class="dropicon icon1" src="@/static/common/my.png" alt="" />
                  我的训练
                </div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div class="flex_row_left" @click="loginOut()">
                  <img class="dropicon icon3" src="@/static/common/out.png" alt="" />
                  退出登录
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- <div style="height: 72px; width: 100%; min-width: 1100px"></div> -->

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" append-to-body>
      <span>确定退出登录吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureout()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  setToken,
  setLoginStatus,
  getLoginStatus,
  removeToken,
  setUserInfo,
  getUserInfo,
  removeUserInfo,
} from "@/utils/auth";
export default {
  name: "pHeader",
  components: {},
  data() {
    return {
      dialogVisible: false,
      isRead: 0,
      code: "",
      isHome: true,
      curentPage: ''
    };
  },
  computed: {
    isLogin: {
      get() {
        return this.$store.state.isLogin;
      },
      set(val) { },
    },
    avatar: {
      get() {
        return this.$store.state.avatar;
      },
      set(val) { },
    },
    nickname: {
      get() {
        return this.$store.state.nickname;
      },
      set(val) { },
    },
  },
  watch: {
    '$route.path': { // $route可以用引号，也可以不用引号  监听的对象
      handler(to, from) {
        this.curentPage = to;
        if (to == '/home' || to == '/login' || to == '/register') {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  created: function () {
    let that = this;
    if (getLoginStatus() && getLoginStatus() == "true") {
      this.$store.commit("CHANGELOGINSTATUS", true);
      this.$store.commit("UPDATAINFO", getUserInfo());
    } else {
      this.$store.commit("CHANGELOGINSTATUS", false);
      removeUserInfo();
    }
  },
  beforeDestroy: function () {
  },
  methods: {
    toHome() {
      this.$router.push("/home");
    },

    sureout() {
      this.axios.post(this.$Api.common.logout, {}).then((res) => {
        if (res.code == 1) {
          setLoginStatus("false");
          removeToken();
          removeUserInfo();
          this.$store.commit("CHANGELOGINSTATUS", false);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          this.dialogVisible = false;
          if (this.curentPage == '/my') {
            this.$router.replace("/");
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    loginOut() {
      this.dialogVisible = true;
      return;
      this.$confirm("确定要退出登录吗?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          setLoginStatus("false");
          removeToken();
          removeUserInfo();
          this.$store.commit("CHANGELOGINSTATUS", false);
          this.$message({
            type: "success",
            message: "退出成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    showLogin() {
      this.$router.push("/login");
    },
    showRegister() {
      this.$router.push("/register");
    },
    toMy() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped lang="scss">
.webHeader {
  width: 100%;
  min-width: 1100px;
  height: 72px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 32px 0px rgba(205, 205, 205, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  .centeerpart {
    width: 1100px;
  }
}
.bgwhite {
  background: #fff;
}
.username {
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #fff;
  margin-right: 10px;
}
.blackusername {
  color: #333333;
}
.logo {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.navtitle {
  height: 36px;
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  white-space: nowrap;
  cursor: pointer;
}
.bluenavtitle {
  color: #2549a1;
}
.opratepart {
  margin-left: 236px;
}

.badge ::v-deep .el-badge__content.is-fixed.is-dot {
  top: 8px;
  right: 8px;
}

.btn {
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 68px;
  height: 32px;
  border-radius: 19px 19px 19px 19px;
  border: 1px solid #ffffff;
  font-size: 16px;
  font-family: Source Han Sans CN-Normal, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  text-align: center;
}
.register {
  background: #ffffff;
  color: #2549a1;
}
.bluebtn {
  border: 1px solid #2549a1;
  color: #2549a1;
}
.whiteregister {
  background: #2549a1;
  color: #ffffff;
}
.downicon {
  width: 12px;
  height: 8px;
  margin-left: 6px;
}

.dropicon {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

::v-deep.el-dropdown-menu__item:focus,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #eb3535;
  background: #ffeded;
  white-space: nowrap;
}

::v-deep.el-dropdown-menu__item:focus .icon1,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon1 {
  content: url(@/static/common/myed.png);
}

::v-deep.el-dropdown-menu__item:focus .icon3,
::v-deep.el-dropdown-menu__item:not(.is-disabled):hover .icon3 {
  content: url(@/static/common/outed.png);
}

::v-deep.el-dropdown-menu {
  width: 122px;
  min-width: 122px;
  right: 0;
}

::v-deep .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #eb3535;
}

::v-deep .el-checkbox__inner:hover {
  border-color: #eb3535;
}

::v-deep .el-button--primary {
  background-color: #eb3535;
  border-color: #eb3535;
}

/* ::v-deep .el-button:focus,
.el-button:hover {
	color: unset;
	border-color: unset;
	background-color: unset;
} */
</style>
