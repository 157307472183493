//1.引入vue
import Vue from "vue"
//2.引入vue-router
import VueRouter from "vue-router";
import home from '@/views/home/home.vue'
//3.注册vue-router
Vue.use(VueRouter);
//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
//4.路径
const routes = [
  {
    path: "/",
    redirect: '/home',
    name: "home",
    component: home,
    meta: {
      title: '首页',
      content: {
        keywords: '脑健康训练',
        description: '脑健康训练小游戏'
      }
    }
  },
  {
    path: "/home",
    name: "home",
    component: home,
    meta: {
      title: '脑健康训练',
      content: {
        keywords: '脑健康训练',
        description: '脑健康训练小游戏'
      }
    }
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/register.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/agreement/agreement.vue"),
  },
  {
    path: "/list",
    name: "list",
    component: () => import("@/views/list/list.vue"),
  }, {
    path: "/detail",
    name: "detail",
    component: () => import("@/views/detail/detail.vue"),
  }, {
    path: "/my",
    name: "my",
    component: () => import("@/views/my/my.vue"),
  },
  // {
  //   path: "/find.html",
  //   name: "find",
  //   component: () => import("@/views/find/index.vue"),
  //   children: [
  //     {
  //       path: "home",
  //       name: "home",
  //       component: () => import("@/views/home/home.vue"),
  //     },
  //   ],
  //   meta: {
  //     title: '脑健康训练',
  //     content: {
  //       keywords: '脑健康训练',
  //       description: '脑健康训练小游戏'
  //     }
  //   }
  // },
];
//4.创建
const router = new VueRouter({
  mode: 'history',//模式
  base: "/",//根路径
  routes,
});
//5.共享
export default router;
