<template>
  <div class="home">
    <div class="top">
      <div class="flex_row_between top-part">
        <div class="left">
          <div class="title">脑健康训练</div>
          <div class="qus">反应迟钝？记忆力下降？ </div>
          <div class="qus">注意力不集中？需警惕大脑亚健康！</div>
          <!-- <div class="btn" @click="goPage('/list',list[0])">大脑训练</div> -->
        </div>
        <img class="right" src="@/static/home/home.png" alt="">
      </div>
    </div>
    <div class="main">
      <div class="flex_row_left">
        <img class="icon" src="@/static/home/icon.png" alt="">
        <div class="main-title">大脑训练游戏</div>
      </div>
      <div class="list flex_row_left">
        <div class="item" @click="goPage('/list',item)" v-for="(item,index) in list" :key="index" :style="{'background':item.color}">
          <img :src="item.image?item.image:''" alt="">
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "web-home",
  components: {},
  data() {
    return {
      list: []
    };
  },
  created: function () {
    scrollTo(0, 1);
    this.getList()
  },
  methods: {
    getList() {
      this.axios.get(this.$Api.common.catelist, {}).then(res => {
        if (res.code == 1) {
          this.list = res.data
        }
      })
    },
    goPage(url, item) {
      let urls = url + '?id=' + item.id + '&name=' + item.name
      this.$router.push(urls);
    }
  },
};
</script>

<style scoped lang="scss">
div {
  box-sizing: border-box;
}
.home {
  width: 100%;
  min-width: 1100px;
}
.top {
  width: 100%;
  height: 680px;
  background: #2549a1;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding-top: 150px;
  &-part {
    width: 1100px;
    margin: 0 auto;
    .left {
      width: 384px;
      height: 530px;
      text-align: center;
      .title {
        height: 72px;
        font-size: 48px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        line-height: 72px;
        margin-top: 113px;
        margin-bottom: 24px;
        text-align: center;
      }
      .qus {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
      .btn {
        width: 220px;
        height: 42px;
        background: #ffffff;
        border-radius: 27px 27px 27px 27px;
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #2549a1;
        line-height: 42px;
        margin: 45px auto 0;
        cursor: pointer;
      }
    }
    .right {
      width: 527px;
      height: 530px;
    }
  }
}
.main {
  width: 1100px;
  margin: 0 auto;
  padding: 58px 0 50px 0;
  .icon {
    width: 30px;
    height: 30px;
    margin-right: 8px;
  }
  &-title {
    height: 42px;
    font-size: 28px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #2549a1;
    line-height: 42px;
  }
  .list {
    margin-top: 38px;
    .item {
      width: 190px;
      height: 218px;
      margin-right: 37px;
      // background: #95e3fa;
      border-radius: 8px 8px 8px 8px;
      text-align: center;
      padding: 36px 52px;
      cursor: pointer;
      img {
        width: 86px;
        height: 86px;
        margin-bottom: 20px;
      }
      .name {
        font-size: 24px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #0c5a71;
        line-height: 36px;
        text-align: center;
      }
    }
    .item:nth-last-child(1) {
      margin-right: 0;
    }
    .item:nth-of-type(1) {
      background: #95e3fa;

      .name {
        color: #0c5a71;
      }
    }

    .item:nth-of-type(2) {
      background: #fccac9;

      .name {
        color: #8b4b4b;
      }
    }

    .item:nth-of-type(3) {
      background: #f6ad7a;

      .name {
        color: #925d39;
      }
    }

    .item:nth-of-type(4) {
      background: #ffdb7d;

      .name {
        color: #926829;
      }
    }

    .item:nth-of-type(5) {
      background: #76e4bc;

      .name {
        color: #196b4e;
      }
    }
  }
}
</style>
