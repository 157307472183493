<template>
  <div class="webFooter">
    <div class="footview">
      <div class="toppart flex_row_between">
        <div class="flex1">
          <div class="tiptitle">联系我们</div>
          <div class="tel">咨询热线：{{info.tel?info.tel:'0752-2219007'}}</div>
          <div class="tel">地址：{{info.address?info.address:'惠州市惠城区红花湖路118号悦道慧谷A座3层'}}</div>
        </div>
        <img class="code" src="@/static/common/qrcode.png" alt="" />
      </div>
      <div class="flex_row_between copyright">
        <div>{{info.filing_year?info.filing_year:'@2023 惠州市医学研究所·版权所有'}}</div>
        <div>{{info.filing_number?info.filing_number:'粤ICP备2023008997号'}}</div>
        <div style="cursor: pointer;" @click="toAgree()">用户协议免责声明</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "pFooter",
  components: {},
  data() {
    return {
      info: {}
    };
  },
  props: {
    showus: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.getaboutUs()
  },
  methods: {
    getaboutUs() {
      this.axios.get(this.$Api.common.aboutUs).then(res => {
        if (res.code == 1) {
          this.info = res.data
        }
      })
    },
    toAgree() {
      let routeData = this.$router.resolve({
        path: "/agreement",
      });
      window.open(routeData.href, "_blank");
    },
    toOther() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.webFooter {
  width: 100%;
  min-width: 1100px;
  height: 251px;
  background: #3a5aad;
  border-radius: 0px 0px 0px 0px;
}
.footview {
  width: 1100px;
  height: 100%;
  margin: 0 auto;
  .code {
    width: 122px;
    height: 122px;
    border-radius: 0px 0px 0px 0px;
  }
  .toppart {
    box-sizing: border-box;
    padding: 25px 0;
    border-bottom: 1px solid #e4e4e4;
  }
}

.tiptitle {
  height: 36px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  text-align: left;
  margin: 5px 0;
}

.tel {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-top: 14px;
}
.copyright {
  font-size: 16px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-top: 26px;
}
</style>
