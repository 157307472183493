let ApiRoot = 'http://game.04nl.com/api/'; //红墨笔
let Api = {
	common: {
		verify: 'sms/send',//发送验证码
		mobileLogin: "user/login",//登录
		register: "user/register",//注册
		resetpwd: "user/resetpwd",//重置密码
		logout: "user/logout",//退出登录
		agreement: "index/agreement",//协议政策
		aboutUs: "index/aboutUs",//联系我们
		catelist: 'cate/list',//分类列表
		gamelist: 'cate/catelist',//游戏列表
		gameotherList: 'game/otherList',//相关推荐
		gamedetail: 'game/detail',//游戏详情
		compareSizes: 'game/compareSizes',//比较大小游戏详情
		addRecord: 'game/addRecord',//添加游戏记录
		history: 'game/history',//训练历史
		weekCount: 'game/weekCount',//柱状图
		trainCount: 'game/trainCount',//统计次数
		count:ApiRoot + 'game/count',//得分柱图
	}
}
export default Api
